<template>
  <nav class="w-full px-4 bg-innobrown relative z-20 text-white">
    <div class="container mx-auto max-w-4xl h-16 flex justify-between text-xs md:text-sm">
       <div class="h-full flex items-center">
        <FullScreenModal />
        <span class="ml-2">{{ email }}</span>
      </div>
      <div class="h-full flex items-center">
        <a href="https://www.chefpaw.com" target="_blank" class="flex justify-center items-center">
          <img src="@/assets/chef_paw_logo_rectangle_white.png" class="h-14">
        </a>
      </div>
    </div>
  </nav>
</template>

<script setup>
  import { computed } from 'vue'
  import FullScreenModal from './FullScreenModal.vue';
  import { useUserStore } from '@/store/user-store'
  const userStore = useUserStore()

  const email = computed(() => {
    if (userStore) {
      return userStore.email
    }
    return ''
  })
</script>